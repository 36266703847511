import React from 'react';
import Helmet from '../project/Helmet';
import RequestDetailActions from './requestDetails/RequestDetailsActions';
import RequestDetailsHeader from './requestDetails/RequestDetailsHeader';
import RequestDetailsInfo from './requestDetails/RequestDetailsInfo';
import RequestDetailsTable from './requestDetails/RequestDetailsTable';
import AttachmentsForDealerAndUser from '../project/AttachmentsForDealerAndUser';
import withDataHOC from '../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {RequestDetail, Attachments} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import RequestAddProduct from './requestDetails/RequestAddProduct';
import RequestAddItem from './requestDetails/RequestAddItem';

/**
 * @fero
 */

class RequestDetailsPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.REQUEST_DETAILS]: RequestDetail.isRequired,
    };

    render() {
        const {
            [GLOBAL_DATA.REQUEST_DETAILS]: requestDetails = {},
            [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile
        } = this.props;
        const userChecked = requestDetails.user != null ? requestDetails.user : {};
        return <React.Fragment>
            <Helmet
                title={`${t`Dopyt`} ${requestDetails.dealer_reference}`}
            />
            <div className="px-3 full-size-height full-size-width overflow-y-auto">
                <RequestDetailActions requestDetails={requestDetails}/>
                <RequestDetailsHeader requestDetails={requestDetails}/>
                <RequestDetailsInfo requestDetails={requestDetails}/>
                { isMobile ? <div className="my-2 d-flex">
                        <RequestAddProduct
                            requestDetails={requestDetails}
                            label={<Trans>Pridať produkt z katalógu</Trans>}
                        />
                        <RequestAddItem
                            requestDetails={requestDetails}
                            label={<Trans>Pridať vlastný produkt</Trans>}
                            className="ml-1"
                        />
                    </div>
                    : null
                }
                <RequestDetailsTable requestDetails={requestDetails}/>
                <AttachmentsForDealerAndUser
                    requestId={requestDetails.id}
                    userId={userChecked.id}
                />
            </div>
        </React.Fragment>;
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(
    withDataHOC([GLOBAL_DATA.REQUEST_DETAILS])(RequestDetailsPage)
);