import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../../locationProvider/locationHOC';
import MakeQuotation from './MakeQuotationFromRequest';
import MakeRequestFromIncoming from './MakeRequestFromIncoming';
import SendRequest from './SendRequest';
import DealModal from '../../project/DealModal';
import HistoryModal from '../../history/HistoryModal';
import RemoveRequest from './../requests/RemoveRequest';
import {RIGHTS} from '../../../constants/Rights';
import {RequestDetail, Location} from '../../../constants/propTypesDefinitions';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {navigateToParametrized} from '../../../lib/url';
import {isTradeSell} from '../../../lib/project';
import {Trans, t} from '@lingui/macro';
import CopyRequestToCart from './CopyRequestToCart';
import MailPreview from '../../project/MailPreview';
import PdfLink from '../../project/PdfLink';
import PageActionsLayout from '../../general/PageActionsLayout';

/**
 * @fero
 */

class RequestDetailsActions extends React.PureComponent {
    static propTypes = {
        requestDetails: RequestDetail.isRequired,
        location: Location.isRequired,
    };

    render() {
        const {location, requestDetails} = this.props;
        const requestId = requestDetails.id;
        return <PageActionsLayout
            back={{
                to: ROUTES.REQUESTS,
                title: <Trans>Dopyty</Trans>
            }}
            actions={[
                {
                    rightsFrom: RIGHTS.DEALER,
                    node: <HistoryModal
                        objectId={requestId}
                        objectName={requestDetails.dealer_reference}
                        objectType="request"
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <MailPreview objectType="request" objectId={requestId}/>
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <PdfLink objectType="request" objectId={requestId}/>
                },
                {
                    node: <DealModal
                        dealPartId={requestId}
                        dealId={requestDetails.id_deal}
                    />
                },
                {
                    node: <SendRequest requestDetails={requestDetails}/>
                },
                {
                    node: <CopyRequestToCart requestDetails={requestDetails}/>
                },
                {
                    rightsFrom: RIGHTS.DEALER,
                    node: isTradeSell(requestDetails.trade) ? <MakeRequestFromIncoming
                        requestDetails={requestDetails}
                        onFinishSuccessful={(result) => {
                            navigateToParametrized(location, ROUTES.REQUEST_DETAILS, {[QUERY_PARAMS.ID_REQUEST]: result});
                            }}
                    /> : null
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <MakeQuotation
                        requestDetails={requestDetails}
                        onFinishSuccessful={(result) => {
                            navigateToParametrized(location, ROUTES.QUOTATION_DETAILS, {[QUERY_PARAMS.ID_QUOTATION]: result});
                        }}
                    />
                },
                {
                    node: <RemoveRequest
                        request={requestDetails}
                        buttonContent={<Trans>Odstrániť</Trans>}
                        onFinishSuccessful={() => {
                            navigateToParametrized(location, ROUTES.REQUESTS, {});
                        }}
                    />
                }
            ]}
        />;
    }
}

export default locationHOC(RequestDetailsActions);