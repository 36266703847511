import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../../locationProvider/locationHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {navigateToParametrized} from '../../../lib/url';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import generalFailedPC from '../../fetch/generalFailedPC';
import { createFetchRequestLoadToCart} from '../../../backend/apiCalls';
import {RequestDetail} from '../../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class CopyRequestToCart extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        requestDetails: RequestDetail.isRequired,
    };

    navigateToCart = () => {
        const {location, requestDetails = {}} = this.props;
        const customer = requestDetails.customer != null ? requestDetails.customer : {};
        const customerId = customer.id;
        navigateToParametrized(location, ROUTES.CART, {
            [QUERY_PARAMS.ID_CUSTOMER]: customerId,
            [QUERY_PARAMS.CART_STEP]: 0,
        });
    };

    render() {
        const {requestDetails} = this.props;
        const requestId = requestDetails.id;
        return <FormModal
            openNode={
                <Button>
                    <Trans>Pridať do košíka</Trans>
                </Button>
            }
            values={{}}
            onFinishSuccessful={this.navigateToCart}
            title={<Trans>Kopírovanie položiek dopytu do košíka</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete pridať položky z dopytu do košíka?</Trans>,
                createFetchRequestLoadToCart(requestId),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa skopírovať položky dopytu do košíka.`)}
        />;
    }
}

export default locationHOC(CopyRequestToCart);