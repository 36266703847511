import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import InputText from '../../general/InputText';
import InputNumber from '../../general/InputNumber';
import {Button, Form} from 'antd';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {focus} from '../../../lib/project';
import {RIGHTS} from '../../../constants/Rights';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {createFetchRequestAddItem} from '../../../backend/apiCalls';
import {isRequestSend} from '../../../lib/project';
import {RequestDetail} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import Tooltip from '../../general/Tooltip';

const FormItem = Form.Item;

/**
 * @fero
 */


class RequestAddItem extends React.PureComponent {
    static propTypes = {
        requestDetails: RequestDetail.isRequired,
        label: PropTypes.node,
        className: PropTypes.string,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {requestDetails, label, className, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        const disabled = isRequestSend(requestDetails);
        return <FormModal
            className={className}
            disabled={disabled}
            openNode={
                <Tooltip title={<Trans>Pridať vlastný produkt</Trans>}>
                    <Button
                        disabled={disabled}
                        size="small"
                        icon="question"
                        type={'primary'}
                    >
                        {label}
                    </Button>
                </Tooltip>
            }
            values={{
                requestId: requestDetails.id,
            }}
            onFinishSuccessful={() => {
                reload([GLOBAL_DATA.REQUEST_DETAILS]);
            }}
            title={<Trans>Pridanie vlastného produktu</Trans>}
            Form={RequestAddItemFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa pridať.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RequestAddItem);

class RequestAddItemForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            requestId: PropTypes.number.isRequired,
        }).isRequired,
        onTradeChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        focus(this.first);
    }

    createHandleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchRequestAddItem(formValues)().then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {onClose, values} = this.props;
        const {requestId} = values;
        const {getFieldDecorator} = this.props.form;
        {getFieldDecorator('id_request', {initialValue: requestId})}
        return <Form>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov</Trans>}>
                {getFieldDecorator('customer_code', {rules: [mandatoryRule]})(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Počet</Trans>}>
                {getFieldDecorator('quantity', {rules: [mandatoryRule]})(
                    <InputNumber/>
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Žiadaná cena za jednotku</Trans>}>
                    {getFieldDecorator('unit_price', {})(
                        <InputNumber/>
                    )}
                </FormItem>
            </RightsWrapper>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámka</Trans>}>
                {getFieldDecorator('customer_notice', {})(
                    <InputText/>
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Interná poznámka</Trans>}>
                    {getFieldDecorator('dealer_notice', {})(
                        <InputText/>
                    )}
                </FormItem>
            </RightsWrapper>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submitDraft" type="primary" loading={loading} onClick={this.createHandleSubmit}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const RequestAddItemFormWrapped = Form.create()(RequestAddItemForm);