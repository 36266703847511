import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {createFetchRequestSend} from '../../../backend/apiCalls';
import {isRequestSend, isRequestEmpty, isRequestFinished, isTradeSell} from '../../../lib/project';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {RequestDetail} from '../../../constants/propTypesDefinitions';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { RIGHTS } from '../../../constants/Rights';


/**
 * @fero
 */

class SendRequest extends React.PureComponent {
    static propTypes = {
        requestDetails: RequestDetail.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {requestDetails, [GLOBAL_DATA.RELOAD_DATA]: reload, rights} = this.props;
        const disabled = isRequestEmpty(requestDetails)
            || (rights < RIGHTS.DEALER && isRequestSend(requestDetails))
            || (rights >= RIGHTS.DEALER && isRequestFinished(requestDetails));
        const customerChecked = requestDetails.customer != null ? requestDetails.customer : {};
        return <FormModal
            disabled={disabled}
            openNode={
                <Button
                    disabled={disabled}
                    type={isRequestSend(requestDetails) ? "" : "primary"}
                >
                    <Trans>Odoslať</Trans>
                </Button>
            }
            values={{}}
            title={<Trans>Odoslanie</Trans>}
            Form={generalNoParameterFormPC(
                <div>
                    <Trans>Naozaj chcete odoslať dopyt?</Trans>
                    {rights >= RIGHTS.DEALER && customerChecked.id_channel == null ?
                        <div className="p-2 d-flex color-red">
                            <Trans>VAROVANIE:</Trans>
                            <div className="pl-2">
                                <Trans>Pre danú organizáciu nie je nastavený komunikačný kanál.</Trans>
                            </div>
                        </div>:
                        null
                    }
                    { requestDetails.user == null ?
                        <div className="p-2 d-flex color-red">
                            <Trans>VAROVANIE:</Trans>
                            <div className="pl-2">
                                <Trans>Nie je nastavená kontakná osoba.</Trans>
                            </div>
                        </div>:
                        null
                    }
                </div>,
                createFetchRequestSend(requestDetails.id),
            )}
            onFinishSuccessful={() => {reload([GLOBAL_DATA.REQUEST_DETAILS])}}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odoslať dopyt.`)}
        />;
    }

}

export default withSessionHOC()(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(SendRequest));