import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../general/Table';
import Tile from '../../general/Tile';
import Link from '../../navigation/Link';
import TileAttribute from '../../general/TileAttribute';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import RemoveRequestItem from './RemoveRequestItem';
import InputTextArea from '../../general/InputTextArea';
import DatePicker from '../../general/DatePicker';
import Date from '../../general/Date';
import InputQuantity from '../../project/InputQuantity';
import InputUnitPrice from '../../project/InputUnitPrice';
import ProductEditableWithMovesRQO from '../../project/ProductEditableWithMovesRQO';
import CustomerCodeEditableRQO from '../../project/CustomerCodeEditableRQO';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {navigateToParametrized, getQueryParam, getQueryParamNumber} from '../../../lib/url';
import {formatQuantity, formatQuantityPerPackage, formatUnitPrice} from '../../../lib/formatting';
import {isNumberAndGreaterThan, numberOrNull, isNumberAndGreaterOrEqual} from '../../../lib/number';
import {getDefaultTablePageSize, isRequestSend} from '../../../lib/project';
import {merge} from '../../../lib/object';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {createFetchRequestEditItem} from '../../../backend/apiCalls';
import {RequestDetail, RequestDetailLine} from '../../../constants/propTypesDefinitions';
import {RIGHTS} from '../../../constants/Rights';
import {Trans, t} from '@lingui/macro';
import InputNumber from '../../general/InputNumber';
import PackageTypeSelect from '../../project/PackageTypeSelect';
import { currentDate } from '../../../lib/date';
import RequestAddItem from './RequestAddItem';
import RequestAddProduct from './RequestAddProduct';
import { stopPropagation } from '../../../lib/interaction';
import { Button, notification } from 'antd';
import InputText from '../../general/InputText';
import UnitPrice from '../../general/UnitPrice';
import SpecialTransportIcon from '../../project/SpecialTransportIcon';

/**
 * @fero
 */

class RequestDetailsTable extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        requestDetails: RequestDetail.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
        };
    }

    setPhrase = (newPhrase) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.REQUEST_DETAIL_PHRASE]: newPhrase,
        });
    };

    setOrdering = (newOrder) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.REQUEST_DETAIL_ORDER_BY]: newOrder,
        });
    };

    setLimit = (newLimit) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.REQUEST_DETAIL_LIMIT]: newLimit,
        });
    };

    setOffset = (newOffset) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.REQUEST_DETAIL_OFFSET]: newOffset,
        });
    };

    render() {
        const {requestDetails = {}, location} = this.props;
        const {showSearch} = this.state;
        const tableData = requestDetails.lines != null ? requestDetails.lines : [];
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        const orderBy = getQueryParam(location, QUERY_PARAMS.REQUEST_DETAIL_ORDER_BY);
        const limit = getQueryParamNumber(location, QUERY_PARAMS.REQUEST_DETAIL_LIMIT);
        const offset = getQueryParamNumber(location, QUERY_PARAMS.REQUEST_DETAIL_OFFSET);
        const phrase = getQueryParam(location, QUERY_PARAMS.REQUEST_DETAIL_PHRASE);
        const doSearch = (phrase != null && phrase != "") || showSearch;

        const isSent = isRequestSend(requestDetails);
        return <div>
            <Table
                BodyRow={isSent ? RequestDetailsTableRowFixed : RequestDetailsTableRowEditable}
                BodyTile={RequestDetailsTile}
                data={tableData}
                customerId={requestDetails.customer != null ? requestDetails.customer.id : null}
                reload={() => {
                    reload([GLOBAL_DATA.REQUEST_DETAILS])
                }}
                notScrollable={true}
                orderBy={orderBy}
                changeOrder={this.setOrdering}
                limit={limit}
                changeLimit={this.setLimit}
                offset={offset}
                changeOffset={this.setOffset}
                pageSize={getDefaultTablePageSize()}
                totalCount={requestDetails.lines_count}
                isSent={isSent}
                colDefs={[
                    {
                        headerCell: <Trans>Č.</Trans>,
                        orderCol: 'sequence',
                        class: 'request-details-table-col-sequence'
                    },
                    {
                        headerCell: doSearch ? 
                            <InputText
                                className="full-size-width"
                                size="small"
                                value={phrase}
                                onChange={this.setPhrase}
                                allowClear={true}
                                placeholder="hľadať ..."
                            />
                            :
                            <div className="d-flex align-items-center">
                                <Trans>Názov produktu</Trans>
                                <Button 
                                    size="small"
                                    icon="search" 
                                    className="ml-2"
                                    onClick={(ev) => {
                                        stopPropagation(ev);
                                        this.setState({showSearch: true});
                                    }}
                                />
                            </div>,
                        orderCol: doSearch ? null : 'designation',
                        class: 'request-details-table-col-designation'
                    },
                    {
                        headerCell: <Trans>Vlastné označenie</Trans>,
                        orderCol: 'customer_code',
                        class: 'request-details-table-col-customer-code'
                    },
                    {
                        headerCell: <Trans>Výrobca</Trans>,
                        orderCol: 'manufacturer',
                        class: 'request-details-table-col-manufacturer'
                    },
                    {
                        headerCell: <Trans>Množstvo</Trans>,
                        orderCol: 'package_quantity',
                        class: 'request-details-table-col-quantity'
                    },
                    {
                        headerCell: <Trans>Požadovaná cena</Trans>,
                        orderCol: 'package_price',
                        class: 'request-details-table-col-unit-price',
                        rightsFrom: RIGHTS.DISTRIBUTOR,
                    },
                    {
                        headerCell: <Trans>Požadovaný termín dodania</Trans>,
                        orderCol: 'requested_at',
                        class: 'request-details-table-col-requested-at'
                    },
                    {
                        headerCell: <Trans>Poznámka</Trans>,
                        class: 'request-details-table-col-notice'
                    },
                    {
                        headerCell: <Trans>Interná poznámka</Trans>,
                        class: 'request-details-table-col-notice',
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        class: (isSent ? 'quotation-details-table-actions-empty' : 'request-details-table-col-actions'),
                        headerCell: <div className="d-flex">
                            <RequestAddProduct
                                requestDetails={requestDetails}
                            />
                            <RequestAddItem
                                requestDetails={requestDetails}
                                className="ml-1"
                            />
                        </div>
                    },
                ]}
            />
        </div>;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RequestDetailsTable));

const requestDetailsItemHOC = (Component) => {
    return class extends React.PureComponent {
        static propTypes = {
            data: RequestDetailLine.isRequired,
            reload: PropTypes.func.isRequired,
            customerId: PropTypes.number.isRequired,
            isSent: PropTypes.bool.isRequired,
        };

        editItemRequest = (queryParams) => {
            const {data, reload} = this.props;
            const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
            fetchHandler(createFetchRequestEditItem(),
                merge(
                    {
                        id_request: data.id_request,
                        id_item: data.id_item
                    },
                    queryParams
                ),
                null,
                reload,
                (error) => {
                    notification['error']({ 
                        message: error.message,
                        duration: 10,
                    });
                }
            );
        };

        onRequestedAtChange = (requestedAt) => {
            this.editItemRequest({requested_at: requestedAt});
        };

        onCustomerNoticeChange = (newNotice) => {
            this.editItemRequest({customer_notice: newNotice});
        };

        onDealerNoticeChange = (newNotice) => {
            this.editItemRequest({dealer_notice: newNotice});
        };

        onCustomerCodeChange = (customerCode) => {
            this.editItemRequest({customer_code: customerCode});
        };

        onPackageTypeChange = (packageType) => {
            this.editItemRequest({id_package_type: packageType});
        };

        onQuantityChange = (newQuantity) => {
            const {reload} = this.props;
            if (isNumberAndGreaterThan(newQuantity, 0))
                this.editItemRequest({package_quantity: newQuantity});
            else
                reload();
        };

        onUnitPriceChange = (newPrice) => {
            const {reload} = this.props;
            if (isNumberAndGreaterOrEqual(newPrice, 0))
                this.editItemRequest({package_price: newPrice});
            else
                reload();
        };

        onSequenceChange = (newSequence) => {
            const {reload} = this.props;
            if (isNumberAndGreaterThan(newSequence, 0))
                this.editItemRequest({sequence: newSequence});
            else
                reload();
        };

        render() {
            const {data, reload, customerId, ...rest} = this.props;
            return <Component
                data={data}
                reload={reload}
                customerId={customerId}
                onRequestedAtChange={this.onRequestedAtChange}
                onCustomerNoticeChange={this.onCustomerNoticeChange}
                onDealerNoticeChange={this.onDealerNoticeChange}
                onCustomerCodeChange={this.onCustomerCodeChange}
                onQuantityChange={this.onQuantityChange}
                onUnitPriceChange={this.onUnitPriceChange}
                onSequenceChange={this.onSequenceChange}
                onPackageTypeChange={this.onPackageTypeChange}
                {...rest}
            />
        }

    }
};

const requestDetailsItem = (Component) => withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(requestDetailsItemHOC(Component));

class RequestDetailsTableRowFixedComponent extends React.PureComponent {
    static propTypes = {
        data: RequestDetailLine.isRequired,
        reload: PropTypes.func.isRequired,
        customerId: PropTypes.number.isRequired,
        isSent: PropTypes.bool.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onCustomerNoticeChange: PropTypes.func.isRequired,
        onDealerNoticeChange: PropTypes.func.isRequired,
        onCustomerCodeChange: PropTypes.func.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
        onUnitPriceChange: PropTypes.func.isRequired,
        onSequenceChange: PropTypes.func.isRequired,
    };

    render() {
        const {data, customerId, onDealerNoticeChange} = this.props;
        return <tr>
            <td>{data.sequence}</td>
            <td>
                <ProductEditableWithMovesRQO
                    productId={data.id_product}
                    customerId={customerId}
                    productDesignation={data.designation}
                    productManufacturer={data.manufacturer}
                    orderCode={data.order_code}
                    quotationsCount={data.quotations_count}
                />
                <SpecialTransportIcon flag={data.special_transport}/>
            </td>
            <td>
                <CustomerCodeEditableRQO
                    productCustomerCode={data.customer_code}
                />
            </td>
            <td>{data.manufacturer}</td>
            <td className="text-right">
                {formatQuantity(data.package_quantity, data.package_type)}
                { data.quantity_per_package != null ?
                    <div className="table-subdata">
                        {formatQuantityPerPackage(data.quantity_per_package, data.quantity_units, data.package_type)}
                    </div> : null
                }
            </td>
            <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                <td className="text-right">
                    <UnitPrice 
                        price={data.package_price} 
                        units={data.package_type} 
                        nullOption={<Trans>neuvedené</Trans>}
                    />
                    { data.id_package_type != null && data.unit_price != null ?
                        <div className="table-subdata">
                            {'('}
                            <UnitPrice price={data.unit_price} units={data.quantity_units}/>
                            {')'}
                        </div> : null
                    }
                </td>
            </RightsWrapper>
            <td><Date dateString={data.requested_at}/></td>
            <td><pre>{data.customer_notice}</pre></td>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <td>
                    <InputTextArea
                        size="small"
                        value={data.dealer_notice}
                        onChange={onDealerNoticeChange}
                    />
                </td>
            </RightsWrapper>
            <td></td>
        </tr>;
    }

}

const RequestDetailsTableRowFixed = requestDetailsItem(RequestDetailsTableRowFixedComponent);

class RequestDetailsTableRowEditableComponent extends React.PureComponent {
    static propTypes = {
        data: RequestDetailLine.isRequired,
        reload: PropTypes.func.isRequired,
        customerId: PropTypes.number.isRequired,
        isSent: PropTypes.bool.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onCustomerNoticeChange: PropTypes.func.isRequired,
        onDealerNoticeChange: PropTypes.func.isRequired,
        onCustomerCodeChange: PropTypes.func.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
        onUnitPriceChange: PropTypes.func.isRequired,
        onSequenceChange: PropTypes.func.isRequired,
        onPackageTypeChange: PropTypes.func.isRequired,
    };

    requestItemSequence = () => {
        const {data, onSequenceChange} = this.props;
        return <InputNumber
            className="text-right"
            size="small"
            value={data.sequence}
            onChange={onSequenceChange}
        />;
    };

    requestItemQuantity = () => {
        const {data, onQuantityChange, onPackageTypeChange} = this.props;
        return <div>
            <InputQuantity
                className="text-right"
                size="small"
                value={numberOrNull(data.package_quantity)}
                onChange={onQuantityChange}
                unit={
                    <PackageTypeSelect
                        size="small"
                        value={data.id_package_type}
                        onChange={onPackageTypeChange}
                        packageInfo={data}
                    />
                }
            />
            { data.quantity_per_package != null ?
                <div className="table-subdata">
                    {formatQuantityPerPackage(data.quantity_per_package, data.quantity_units, data.package_type)}
                </div> : null
            }
        </div>;
    };

    requestItemUnitPrice = () => {
        const {data, onUnitPriceChange} = this.props;
        return <div>
            <InputUnitPrice
                size="small"
                value={data.package_price}
                onChange={onUnitPriceChange}
                unit={data.package_type}
            />
            { data.id_package_type != null && data.unit_price != null ?
                <div className="table-subdata">
                    {'('}
                    <UnitPrice price={data.unit_price} units={data.quantity_units}/>
                    {')'}
                </div> : null
            }
        </div>;

    };

    requestItemCustomerNotice = () => {
        const {data, onCustomerNoticeChange} = this.props;
        return <InputTextArea
            size="small"
            value={data.customer_notice}
            onChange={onCustomerNoticeChange}
        />;
    };

    requestItemDealerNotice = () => {
        const {data, onDealerNoticeChange} = this.props;
        return [
            <RightsWrapper key="customer" to={RIGHTS.DISTRIBUTOR}>
                {data.dealer_notice}
            </RightsWrapper>,
            <RightsWrapper key="dealer" from={RIGHTS.MARKETING}>
                <InputTextArea
                    size="small"
                    value={data.dealer_notice}
                    onChange={onDealerNoticeChange}
                />
            </RightsWrapper>
        ];
    };

    render() {
        const {data, customerId, onCustomerCodeChange, onRequestedAtChange} = this.props;
        return <tr>
            <td>{this.requestItemSequence()}</td>
            <td>
                <ProductEditableWithMovesRQO
                    productId={data.id_product}
                    customerId={customerId}
                    productDesignation={data.designation}
                    productManufacturer={data.manufacturer}
                    orderCode={data.order_code}
                    quotationsCount={data.quotations_count}
                />
                <SpecialTransportIcon flag={data.special_transport}/>
            </td>
            <td>
                <CustomerCodeEditableRQO
                    productCustomerCode={data.customer_code}
                    onCustomerCodeChange={onCustomerCodeChange}
                />
            </td>
            <td>{data.manufacturer}</td>
            <td className="text-right">{this.requestItemQuantity()}</td>
            <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                <td className="text-right">{this.requestItemUnitPrice()}</td>
            </RightsWrapper>
            <td>
                <DatePicker
                    size="small"
                    value={data.requested_at}
                    min={currentDate()}
                    onChange={onRequestedAtChange}
                />
            </td>
            <td>{this.requestItemCustomerNotice()}</td>
            <RightsWrapper key="dealer" from={RIGHTS.MARKETING}>
                <td>{this.requestItemDealerNotice()}</td>
            </RightsWrapper>
            <td>
                <RemoveRequestItem
                    requestDetailsLine={data}
                />
            </td>
        </tr>;
    }
}

const RequestDetailsTableRowEditable = requestDetailsItem(RequestDetailsTableRowEditableComponent);

class RequestDetailsTileComponent extends React.PureComponent {
    static propTypes = {
        data: RequestDetailLine.isRequired,
        reload: PropTypes.func.isRequired,
        customerId: PropTypes.number.isRequired,
        isSent: PropTypes.bool.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onCustomerNoticeChange: PropTypes.func.isRequired,
        onDealerNoticeChange: PropTypes.func.isRequired,
        onCustomerCodeChange: PropTypes.func.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
        onUnitPriceChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            data, customerId, isSent, onCustomerCodeChange, onRequestedAtChange, onCustomerNoticeChange,
            onDealerNoticeChange, onUnitPriceChange, onQuantityChange

        } = this.props;
        return <Tile className="p-2">
            <div className="flex-row-dynamic-static">
                <div className="align-self-center align-self-center">
                    {
                        data.designation && <Link
                            className="text-dark"
                            to={ROUTES.PRODUCT_DETAILS}
                            queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_product}}
                        >
                            <h4 className="px-2 mt-1">{data.designation + ' (' + data.manufacturer + ')'}</h4>
                        </Link>
                    }
                    <TileAttribute
                        title={<Trans>Vlastné ozn.</Trans>}
                        value={
                            isSent ?
                                data.customer_code :
                                <CustomerCodeEditableRQO
                                    productCustomerCode={data.customer_code}
                                    onCustomerCodeChange={onCustomerCodeChange}
                                />
                        }
                    />
                </div>
                {
                    !isSent &&
                    <RemoveRequestItem
                        buttonClassName="m-1"
                        requestDetailsLine={data}
                    />
                }
            </div>
            <TileAttribute
                title={<Trans>Množstvo</Trans>}
                value={
                    isSent ?
                        formatQuantity(data.quantity, data.quantity_units) :
                        <InputQuantity
                            className="text-right"
                            size="small"
                            value={numberOrNull(data.quantity)}
                            onChange={onQuantityChange}
                            unit={data.quantity_units}
                        />
                }
            />
            <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                <TileAttribute
                    title={<Trans>Požadovaná cena</Trans>}
                    value={
                        isSent ?
                            <UnitPrice
                                price={data.unit_price}
                                units={data.quantity_units}
                                nullOption={<Trans>neuvedené</Trans>}
                            />
                            :
                            <InputUnitPrice
                                size="small"
                                value={data.unit_price}
                                onChange={onUnitPriceChange}
                                unit={data.quantity_units}
                            />
                    }
                />
            </RightsWrapper>
            <TileAttribute
                title={<Trans>Pož. termín dodania</Trans>}
                value={
                    isSent ?
                        <Date dateString={data.requested_at}/> :
                        <DatePicker
                            size="small"
                            value={data.requested_at}
                            min={currentDate()}
                            onChange={onRequestedAtChange}
                        />
                }
            />
            <TileAttribute
                className="full-size-width flex-wrap"
                title={<Trans>Poznámka</Trans>}
                value={
                    <React.Fragment>
                        {isSent ?
                            <pre>{data.customer_notice}</pre> :
                            <InputTextArea
                                size="small"
                                value={data.customer_notice}
                                onChange={onCustomerNoticeChange}
                            />

                        }
                    </React.Fragment>
                }
            />
            <RightsWrapper from={RIGHTS.MARKETING}>
                <TileAttribute
                    className="full-size-width flex-wrap"
                    title={<Trans>Interná poznámka</Trans>}
                    value={
                        <InputTextArea
                            size="small"
                            value={data.dealer_notice}
                            onChange={onDealerNoticeChange}
                        />
                    }
                />
            </RightsWrapper>
            <SpecialTransportIcon flag={data.special_transport} className="px-2"/>
        </Tile>
    }
}

const RequestDetailsTile = requestDetailsItem(RequestDetailsTileComponent);