import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchRequestRemoveItem} from '../../../backend/apiCalls';
import {RequestDetailLine} from '../../../constants/propTypesDefinitions';
import Tooltip from '../../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */


class RemoveRequestItem extends React.PureComponent {
    static propTypes = {
        requestDetailsLine: RequestDetailLine.isRequired,
        buttonClassName: PropTypes.string,
    };

    reloadData = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.REQUEST_DETAILS]);
    };

    render() {
        const {requestDetailsLine, buttonClassName} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstrániť položku</Trans>}>
                    <Button className={buttonClassName ? buttonClassName : ''} size="small" type="danger" icon="close"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={this.reloadData}
            title={<Trans>Odstránenie položky</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odstrániť položku z dopytu?</Trans>,
                () => {
                    return createFetchRequestRemoveItem()({
                        id_request: requestDetailsLine.id_request,
                        id_item: requestDetailsLine.id_item,
                    })
                },
            )}
                Response={null}
                Failed={generalFailedPC(t`Nepodarilo sa odstrániť položku z dopytu.`)}
                />;
            }

}

    export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RemoveRequestItem);