import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import CustomersSelect from '../../project/CustomersSelect';
import UserSelectByCustomer from '../../project/UserSelectByCustomer';
import InputTextArea from '../../general/InputTextArea';
import {Button, Form} from 'antd';
import {focus} from '../../../lib/project';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {createFetchRequestFromIncoming} from '../../../backend/apiCalls';
import {isRequestSend} from '../../../lib/project';
import {RequestDetail} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
const FormItem = Form.Item;

/**
 * @fero
 */


class MakeRequestFromIncoming extends React.PureComponent {
    static propTypes = {
        requestDetails: RequestDetail.isRequired,
        onFinishSuccessful: PropTypes.func.isRequired,
    };

    render() {
        const {requestDetails = {}, onFinishSuccessful} = this.props;
        const disabled = !isRequestSend(requestDetails);
        return <FormModal
            disabled={disabled}
            openNode={
                <Button disabled={disabled}>
                    <Trans>Vytvoriť dopyt</Trans>
                </Button>
            }
            values={{requestDetails}}
            onFinishSuccessful={(result) => {
                if(onFinishSuccessful != null) {
                    onFinishSuccessful(result);
                }
            }}
            title={<Trans>Vytvorenie odchádzajúceho dopytu z prichádzajúceho</Trans>}
            Form={MakeRequestFromIncomingFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa vytvoriť dopyt.`)}
        />
    }

}

export default MakeRequestFromIncoming;

class MakeRequestFromIncomingForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            requestDetails: RequestDetail.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            currentCustomerId: null,
        };
    }

    componentDidMount() {
        focus(this.first);
    }

    componentDidUpdate() {
        const {currentCustomerId} = this.state;
        const {getFieldValue, resetFields} = this.props.form;
        const formCustomerId = getFieldValue('id_customer');
        if(formCustomerId !== currentCustomerId) {
            resetFields(['id_user']);
            this.setState({
                currentCustomerId: formCustomerId,
            })
        }

    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchRequestFromIncoming()(formValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {onClose, values} = this.props;
        const {requestDetails = {}} = values;
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const customerId = getFieldValue('id_customer');
        {getFieldDecorator('id', {initialValue: requestDetails.id})}
        return <Form>
            <FormItem
                {...formItemLayout}
                label={<Trans>Organizácia</Trans>}>
                {getFieldDecorator('id_customer', {rules: [mandatoryRule]})(
                    <CustomersSelect/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Kontakt</Trans>}>
                {getFieldDecorator('id_user', {})(
                    <UserSelectByCustomer
                        customerId={customerId}
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámka</Trans>}>
                {getFieldDecorator('customer_notices', {})(
                    <InputTextArea/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Interná poznámka</Trans>}>
                {getFieldDecorator('dealer_notices', {})(
                    <InputTextArea/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submitDraft" type="primary" loading={loading} onClick={this.handleSubmit}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const MakeRequestFromIncomingFormWrapped = Form.create()(MakeRequestFromIncomingForm);