import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../../locationProvider/locationHOC';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import Link from '../../navigation/Link';
import {RIGHTS} from '../../../constants/Rights';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {RequestDetail, Location} from '../../../constants/propTypesDefinitions';
import {isTradeSell, getBackgroundStyle} from '../../../lib/project';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class RequestDetailsHeader extends React.PureComponent {
    static propTypes = {
        requestDetails: RequestDetail.isRequired,
        location: Location,
    };

    render() {
        const {requestDetails, location} = this.props;
        return <React.Fragment>
            <h2 className={'text-center p-3 ' + getBackgroundStyle(requestDetails.trade)}>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    {
                        isTradeSell(requestDetails.trade) ?
                            <Trans>Detail prijatého dopytu č.</Trans> :
                            <Trans>Detail odoslaného dopytu č.</Trans>
                    }
                </RightsWrapper>
                <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                    {
                        isTradeSell(requestDetails.trade) ?
                            <Trans>Detail dopytu č.</Trans> :
                            <Trans>Detail dopytu č.</Trans>
                    }
                </RightsWrapper>
                {' '}
                {requestDetails.dealer_reference}
                {' ('}
                {requestDetails.status}
                {')'}
            </h2>
            {
                requestDetails.id_quotation != null ?
                    <h4 className="text-center py-3">
                        <Trans>Cenová ponuka č.</Trans>
                        {' '}
                        <Link
                            location={location}
                            to={ROUTES.QUOTATION_DETAILS}
                            queryParams={{[QUERY_PARAMS.ID_QUOTATION]: requestDetails.id_quotation}}
                        >
                            {requestDetails.quotation_reference}
                        </Link>
                    </h4>
                    : null
            }
            {
                requestDetails.id_draft != null ?
                    <RightsWrapper from={RIGHTS.MARKETING}>
                        <h4 className="text-center py-3">
                            <Link
                                location={location}
                                to={ROUTES.QUOTATION_DETAILS}
                                queryParams={{[QUERY_PARAMS.ID_QUOTATION]: requestDetails.id_draft}}
                            >
                                <Trans>Rozpracovaná cenová ponuka k dopytu</Trans>
                            </Link>
                        </h4>
                    </RightsWrapper>
                    : null
            }
        </React.Fragment>;
    }
}

export default locationHOC(RequestDetailsHeader);