import React from 'react';
import PropTypes from 'prop-types';
import AddProductToRequest from './AddProductToRequest';
import ProductModalTable from '../../project/ProductModalTable';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {RequestDetail} from '../../../constants/propTypesDefinitions';
import {isRequestSend} from '../../../lib/project';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import EditActions from '../../products/table/EditActions';
import Tooltip from '../../general/Tooltip';

/**
 * @fero
 */

class RequestAddProduct extends React.PureComponent {
    static propTypes = {
        requestDetails: RequestDetail.isRequired,
        className: PropTypes.string,
        label: PropTypes.node,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {requestDetails = {}, className, label, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        const {customer = {}} = requestDetails;
        return <ProductModalTable
            className={className}
            customerId={customer.id}
            hideGeneric={true}
            requestId={requestDetails.id}
            modalTitle={<span><Trans>Pridanie produktov do dopytu</Trans></span>}
            MainRowActions={AddProductToRequest}
            InfoRowActions={EditActions}
            onClose={() => {
                reload([GLOBAL_DATA.REQUEST_DETAILS]);
            }}
            openNode={
                <Tooltip title={<Trans>Pridať produkt z ponuky</Trans>}>
                    <Button
                        disabled={isRequestSend(requestDetails)}
                        size="small"
                        icon="plus"
                        onClick={this.onOpen}
                        type={'primary'}
                    >
                        {label}
                    </Button>
                </Tooltip>
            }
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RequestAddProduct);