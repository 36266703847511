import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import QuotationDivisibilitySelect from '../../project/QuotationDivisibilitySelect';
import {Button, Form} from 'antd';
import {focus} from '../../../lib/project';
import {formItemLayout, tailFormItemLayout} from '../../../constants/form';
import {createFetchRequestMakeQuotation} from '../../../backend/apiCalls';
import {isRequestSend} from '../../../lib/project';
import {RequestDetail} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import TransportTypeSelect from '../../project/TransportTypeSelect';
import PaymentMethodSelect from '../../project/PaymentMethodSelect';
const FormItem = Form.Item;

/**
 * @fero
 */


class MakeQuotationFromRequest extends React.PureComponent {
    static propTypes = {
        requestDetails: RequestDetail.isRequired,
        onFinishSuccessful: PropTypes.func,
    };

    render() {
        const {requestDetails = {}, onFinishSuccessful} = this.props;
        const requestId = requestDetails.id;
        const disabled = !isRequestSend(requestDetails) || 
            requestDetails.id_quotation != null ||
            requestDetails.id_draft != null;

        return <FormModal
            disabled={disabled}
            openNode={
                <Button
                    disabled={disabled}
                    type="primary"
                >
                    <Trans>Vytvoriť cenovú ponuku</Trans>
                </Button>
            }
            values={{requestId, requestDetails}}
            onFinishSuccessful={(result) => {
                if(onFinishSuccessful != null) {
                    onFinishSuccessful(result);
                }
            }}
            title={<Trans>Vytvorenie cenovej ponuky</Trans>}
            Form={MakeQuotationFromRequestFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa vytvoriť cenovú ponuku.`)}
        />
    }

}

export default MakeQuotationFromRequest;

class MakeQuotationFromRequestForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            requestId: PropTypes.number.isRequired,
            requestDetails: RequestDetail.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transportTypeId: null,
            paymentMethodId: null,
        };
    }

    componentDidMount() {
        focus(this.first);
    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchRequestMakeQuotation()(formValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    onTransportTypeChanged = (newType) => {
        this.setState({transportTypeId: newType});
    };

    onPaymentMethodChanged = (newType) => {
        this.setState({paymentMethodId: newType});
    };

    render() {
        const {loading, transportTypeId, paymentMethodId} = this.state;
        const {onClose, values} = this.props;
        const {requestId, requestDetails = {}} = values;
        const customerDetails = requestDetails.customer != null ? requestDetails.customer : {};
        const {getFieldDecorator} = this.props.form;
        {getFieldDecorator('id_request', {initialValue: requestId})}
        return <Form>
            <FormItem
                {...formItemLayout}
                label={<Trans>Deliteľnosť</Trans>}>
                {getFieldDecorator('divisibility_level', {})(
                    <QuotationDivisibilitySelect/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Spôsob dopravy</Trans>}>
                {getFieldDecorator('id_transport_type', {
                    initialValue: customerDetails.id_transport_type,
                    onChange: this.onTransportTypeChanged
                })(
                    <TransportTypeSelect paymentMethodId={paymentMethodId} allowClear={true}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Spôsob platby</Trans>}>
                {getFieldDecorator('id_payment_method', {
                    initialValue: customerDetails.id_payment_method,
                    onChange: this.onPaymentMethodChanged
                })(
                    <PaymentMethodSelect transportTypeId={transportTypeId} allowClear={true}/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submitDraft" type="primary" loading={loading} onClick={this.handleSubmit}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const MakeQuotationFromRequestFormWrapped = Form.create()(MakeQuotationFromRequestForm);